import BasicTemplate from "./BasicTemplate";
import BasicTvTemplate from "./BasicTvTemplate";
import ChannelDetail from "./ChannelDetail";
import ChannelsList from "./ChannelsList";
import Error404 from "./Error404";
import EventDetail from "./EventDetail";
import EventsList from "./EventsList";
import Landing from "./Landing";
import LiveStream from "./LiveStream";
import NewsDetail from "./NewsDetail";
import NewsList from "./NewsList";
import PressReleasesDetail from "./PressReleasesDetail";
import PressReleasesList from "./PressReleasesList";
import ProgramComparator from "./ProgramComparator";
import ProgramDetail from "./ProgramDetail";
import ProgramLanding from "./ProgramLanding";
import ProgramsList from "./ProgramsList";
import ProjectDetail from "./ProjectDetail";
import ProjectsList from "./ProjectsList";
import QualityTemplate from "./QualityTemplate";
import SearchResults from "./SearchResults";
import SearchResultsGalleries from "./SearchResultsGalleries";
import SearchResultsTv from "./SearchResultsTv";
import SearchVideoCreator from "./SearchVideoCreator";
import SearchVideoTags from "./SearchVideoTags";
import VideoDetail from "./VideoDetail";
import VideosList from "./VideosList";

export default {
	BasicTemplate,
	BasicTvTemplate,
	ChannelDetail,
	ChannelsList,
	Error404,
	EventDetail,
	EventsList,
	Landing,
	LiveStream,
	NewsDetail,
	NewsList,
	PressReleasesDetail,
	PressReleasesList,
	ProgramComparator,
	ProgramDetail,
	ProgramLanding,
	ProgramsList,
	ProjectDetail,
	ProjectsList,
	QualityTemplate,
	SearchResults,
	SearchResultsGalleries,
	SearchResultsTv,
	SearchVideoCreator,
	SearchVideoTags,
	VideoDetail,
	VideosList,
};
