import AcademicAreaSelector from "./AcademicAreaSelector";
import EmailField from "./EmailField";
import EntryDatesSelector from "./EntryDatesSelector";
import LegalField from "./LegalField";
import NumberField from "./NumberField";
import PhoneField from "./PhoneField";
import ProgramSelector from "./ProgramSelector";
import ProgramTypeSelector from "./ProgramTypeSelector";
import TextField from "./TextField";

const formFields = {
	AcademicAreaSelector,
	EmailField,
	EntryDatesSelector,
	LegalField,
	NumberField,
	PhoneField,
	ProgramSelector,
	ProgramTypeSelector,
	TextField,
};

export { formFields };
