import { ProgramSelectorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<ProgramSelectorProps> = {
	schemaType: "formComponent",
	component: "ProgramSelector",
	displayName: "Program Selector",

	configTabs: [
		{
			title: "Content",
			fields: [
				{
					type: "NoteField",
					title: "title",
					key: "note",
					value: { title: "value title", text: "value text" },
					helptext: "helptext",
					// TODO
					// 1 explicar que este campo cambiará dependiendo del área (si existe)
				},
				{
					...crmKey,
					title: "CRM key for program 1",
					key: "crmKeyProgram1",
				},
				{
					...crmKey,
					title: "CRM key for program 2",
					key: "crmKeyProgram2",
				},
				{
					...crmKey,
					title: "CRM key for program 3",
					key: "crmKeyProgram3",
				},
				{ ...label },
				{
					type: "ReferenceField",
					title: "",
					key: "data",
					sources: [{ structuredData: "PROGRAM" }],
					mandatory: true,
				},
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "ProgramSelector",
		// hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [{ structuredData: "PROGRAM" }],
		},
		fieldWidth: "100",
	},
};

export default schema;
