import { CustomBasicFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { ctaLabel } from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomBasicFormProps> = {
	schemaType: "formTemplate",
	displayName: "Basic Form",
	component: "CustomBasicForm",

	content: [
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			key: "formSubject",
			title: "Form subject",
			helptext: "Used in com_name and com_categoriaorigen fields in CRM",
		},
		{
			type: "FormFieldArray",
			title: "Form fields",
			key: "formFields",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"EntryDatesSelector",
				"LegalField",
			],
			// TODO leadsourcecode o com_requestchannel
		},
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomBasicForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		formSubject: "Contacto básico",
		formFields: [],
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomBasicForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomBasicForm/thumbnail@2x.png",
	},
};

export default schema;
