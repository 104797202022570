import { Component } from "@griddo/core";
import * as React from "react";

// Types
import type { AcademicAreaSelectorProps } from "./AcademicAreaSelector";
import type { EmailFieldProps } from "./EmailField";
import type { EntryDatesSelectorProps } from "./EntryDatesSelector";
import type { LegalFieldProps } from "./LegalField";
import type { NumberFieldProps } from "./NumberField";
import type { PhoneFieldProps } from "./PhoneField";
import type { ProgramTypeSelectorProps } from "./ProgramTypeSelector";
import type { TextFieldProps } from "./TextField";

// Components
const AcademicAreaSelector = React.lazy(() => import("./AcademicAreaSelector"));
const EmailField = React.lazy(() => import("./EmailField"));
const EntryDatesSelector = React.lazy(() => import("./EntryDatesSelector"));
const LegalField = React.lazy(() => import("./LegalField"));
const NumberField = React.lazy(() => import("./NumberField"));
const PhoneField = React.lazy(() => import("./PhoneField"));
const ProgramTypeSelector = React.lazy(() => import("./ProgramTypeSelector"));
const TextField = React.lazy(() => import("./TextField"));

// Components for <GriddoComponent>
const components = {
	AcademicAreaSelector,
	EmailField,
	EntryDatesSelector,
	LegalField,
	NumberField,
	PhoneField,
	ProgramTypeSelector,
	TextField,
};

// Types for <GriddoComponent>
type ComponentProps =
	| AcademicAreaSelectorProps
	| EmailFieldProps
	| EntryDatesSelectorProps
	| LegalFieldProps
	| NumberFieldProps
	| PhoneFieldProps
	| ProgramTypeSelectorProps
	| TextFieldProps;

// Component named export
export {
	AcademicAreaSelector,
	EmailField,
	EntryDatesSelector,
	LegalField,
	NumberField,
	PhoneField,
	ProgramTypeSelector,
	TextField,
	// ...
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default components;

/**
 * `<GriddoFormField>`
 *
 * Render components with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoFormField component="EmailField" {...emailFieldProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoFormField = (props: ComponentProps) => (
	<Component libComponents={components} {...props} />
);
