import { Schema } from "@griddo/core";

export const PROGRAM: Schema.PageContentType = {
	dataPacks: ["PROGRAMS"],
	title: "Program",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["ProgramDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "TextField",
				title: "Id API (titIdWeb)",
				from: "idAPI",
				key: "idAPI",
			},
			{
				type: "TextField",
				title: "Acronym (titSiglas)",
				from: "acronym",
				key: "acronym",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},

			{
				title: "Image",
				type: "ImageField",
				key: "image",
				from: "image",
			},
			{
				type: "MultiCheckSelect",
				title: "Centers",
				placeholder: "Centers",
				from: "categories.centers",
				key: "centers",
				source: "CENTER",
			},
			{
				type: "MultiCheckSelect",
				title: "Schools",
				placeholder: "Schools",
				from: "categories.schools",
				key: "schools",
				source: "SCHOOL",
			},
			{
				type: "MultiCheckSelect",
				title: "Program type",
				placeholder: "Program type",
				from: "categories.programTypes",
				key: "programTypes",
				source: "PROGRAM_TYPE",
			},
			{
				type: "MultiCheckSelect",
				title: "Certification",
				placeholder: "Certification",
				from: "categories.certifications",
				key: "certifications",
				source: "CERTIFICATION",
			},
			{
				type: "MultiCheckSelect",
				title: "Academic area",
				placeholder: "Academic area",
				from: "categories.academicAreas",
				key: "academicAreas",
				source: "ACADEMIC_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Languages",
				placeholder: "Languages",
				from: "categories.languages",
				key: "languages",
				source: "LANGUAGE",
			},
			{
				type: "MultiCheckSelect",
				title: "Modalities",
				placeholder: "Modalities",
				from: "categories.modalities",
				key: "modalities",
				source: "MODALITY",
			},
			{
				type: "MultiCheckSelect",
				title: "Formats",
				placeholder: "Formats",
				from: "categories.formats",
				key: "formats",
				source: "FORMAT",
			},
			{
				type: "MultiCheckSelect",
				title: "Calls for registrations",
				placeholder: "Calls for registrations",
				from: "categories.registrations",
				key: "registrations",
				source: "REGISTRATION",
			},
			{
				type: "MultiCheckSelect",
				title: "Campus",
				placeholder: "Campus",
				from: "categories.campus",
				key: "campus",
				source: "CAMPUS",
			},
			{
				type: "DateField",
				title: "Starts On",
				from: "intake",
				key: "intake",
			},
			{
				type: "TextField",
				title: "Duration",
				from: "duration",
				key: "duration",
			},
			{
				type: "TextField",
				title: "Seats",
				from: "seats",
				key: "seats",
			},
			{
				type: "TextField",
				title: "Admission dates",
				from: "admissionDates",
				key: "admissionDates",
			},
			{
				type: "TextField",
				title: "Admission URL",
				from: "admissionUrl",
				key: "admissionUrl",
			},
			{
				title: "Document to download",
				type: "ReferenceField",
				key: "data",
				from: "data",
				sources: [{ structuredData: "DOCUMENT" }],
				selectionType: ["manual"],
			},
			{
				title: "Highlighted tag",
				type: "AsyncSelect",
				key: "tag",
				from: "tag",
				source: "PROGRAM_TAG",
			},
			{
				type: "TextField",
				key: "centerOrder",
				from: "centerOrder",
				title: "Center order",
				indexable: true,
			},
			{
				type: "TextField",
				key: "certificationOrder",
				from: "certificationOrder",
				title: "Certification order",
				indexable: true,
			},

			// Comparator fields

			{
				type: "NumberField",
				title: "Practices hours",
				key: "practicesHours",
				from: "practicesHours",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Resume",
				key: "resume",
				from: "resume",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Career opportunities",
				key: "opportunities",
				from: "opportunities",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Candidate profile",
				key: "profile",
				from: "profile",
			},
			{
				type: "ReferenceField",
				title: "Exchanges",
				key: "agreementsData",
				from: "agreementsData",
				sources: [{ structuredData: "AGREEMENTS" }],
			},
			{
				type: "TextField",
				title: "Registration payment",
				key: "registrationPayment",
				from: "registrationPayment",
			},
			{
				type: "TextField",
				title: "Monthly payment",
				key: "monthlyPayment",
				from: "monthlyPayment",
			},
			{
				type: "NumberField",
				title: "Monthly fees",
				key: "monthlyFees",
				from: "monthlyFees",
			},
			{
				type: "TextField",
				title: "Total",
				key: "totalPrice",
				from: "totalPrice",
			},
		],
	},
};
